import { Column, Row } from 'components/v2/Grid'
import { Text } from 'components/v2/Text'
import { Button } from 'components/v2/Button'
import { Close } from 'components/v2/Icons'
import { Modal } from 'components/v2/Modal'
import { ModalContainer, StyledColumn, StyledImage } from './styles'
import Toast from './Toast'
import { useEffect, useState } from 'react'
import { checkIsMobile } from 'utils/helpers'

const FirstFold = ({
  handleModal,
  frontend_is_boticario_benefit_modal_feature_enabled,
  isBoticarioModalOpen,
  setIsBoticarioModalOpen
}) => {
  const [showToast, setShowToast] = useState(true)
  const isMobile = checkIsMobile(993)

  const closeToast = () => {
    localStorage.setItem('showFirstFoldToast', false)
    setShowToast(false)
  }

  useEffect(() => {
    const showFirstFoldToast = localStorage.getItem('showFirstFoldToast')

    if (showFirstFoldToast === null) {
      localStorage.setItem('showFirstFoldToast', true)
    }

    if (showFirstFoldToast === 'false') {
      setShowToast(false)
    }
  }, [])

  return (
    <Row
      background='#FF7708'
      height='100vh'
      justifyContent='center'
      position='relative'
      overflow='hidden'
      p='16px'
    >
      {frontend_is_boticario_benefit_modal_feature_enabled && (
        <Modal
          isOpened={isBoticarioModalOpen}
          setIsOpened={open => setIsBoticarioModalOpen(open)}
          shouldCloseOnClickOutside={true}
          shouldCloseOnEscape={true}
          withBackground={true}
        >
          {({ innerModalRef }) => (
            <ModalContainer>
              <Column width='100%' height='100%'>
                <Row
                  onClick={() => setIsBoticarioModalOpen(false)}
                  justifyContent='flex-end'
                  mb='8px'
                >
                  <Close size='14px' />
                </Row>
                <Column
                  width='100%'
                  height='100%'
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <Text
                    fontWeight='600'
                    variant='bigger'
                    lineHeight='24px'
                    color='gray.n900'
                    mt='30px'
                    mb='20px'
                  >
                    Não localizamos seu benefício corporativo
                  </Text>

                  <Column width='400px' alignItems='center' justifyContent='center'>
                    <img
                      src='/static/images/icon_error.svg'
                      alt='Ícone de Alerta'
                      title='Ícone de Alerta'
                      width={38}
                      height={38}
                    />
                  </Column>

                  <Column width='100%' alignItems='flex-start' mb='20px'>
                    <Text variant='small' color='gray.n900' lineHeight='21px' textAlign='left'>
                      Desculpe, não localizamos o seu benefício por esse dado, mas você pode tentar
                      outra chave de acesso, <b>como e-mail, CPF, matrícula, crachá ou token</b>.
                      Caso o erro persista,{' '}
                      <a
                        as='a'
                        href='https://meajuda.vittude.com/pt-BR/articles/6845319-como-ativar-meu-beneficio-corporativo/'
                        target='_blank'
                        passHref
                      >
                        entre em contato com nosso suporte.
                      </a>
                    </Text>
                  </Column>
                </Column>
              </Column>
            </ModalContainer>
          )}
        </Modal>
      )}

      <Row px='20px' maxWidth='1200px' width='100%' alignItems='center' zIndex={2}>
        <StyledColumn justifyContent='flex-start' sm='12' md='6' mt={['300px', '0']}>
          <Text color='white' mb='1'>
            TERAPIA ONLINE
          </Text>
          <Text fontWeight='600' fontSize='40px' color='white' mb='24px'>
            Seu psicólogo a qualquer <br />
            hora, em qualquer lugar.
          </Text>
          <Button
            bg='white'
            color='primary'
            height='56px'
            fontSize='14px'
            fontWeight='600'
            lineHeight='19px'
            variant='primary-refit'
            maxWidth='200px'
            onClick={() => handleModal()}
          >
            Agendar consulta
          </Button>
        </StyledColumn>
      </Row>

      <StyledImage src='/static/images/home-hero.png' alt='Boneca sentada na lua' />

      {!isMobile && showToast && <Toast handleModal={handleModal} closeToast={closeToast} />}
    </Row>
  )
}

export default FirstFold
