import moment from 'moment'
import api from './api'

export const getInsertedEvaluations = (user_id, page) =>
  api.get(`/patient/${user_id}/get_evaluations/?page=${page}`)

export const getPendingEvaluations = (user_id, page) =>
  api.get(`/patient/${user_id}/get_pending_evaluations/?page=${page}`)

export const newEvaluation = (consultation_id, user_id) => {
  /*eslint-disable-line */
  return new Promise((resolve, reject) => {
    api
      .post(`/patient/${user_id}/get_consultation/`, {
        consultation_id
      }) /*eslint-disable-line */
      .then(response => {
        const json = response.data.content
        json.evaluation = {}
        json.rating = {}
        json.rating.attention = 0
        json.rating.punctuality = 0
        json.rating.knowledge = 0
        json.anonymous = false
        json.comment = ''
        json.consultation = {
          id: consultation_id,
          professional: json.professional
        }
        resolve(json)
      })
      .catch(error => reject(error))
  })
}

export const feedbackConfirm = (uid, token, evaluation) =>
  api.post(`/feedback/confirm/${uid}/${token}/`, { evaluation })

export const createNewConsultationEvaluation = (user_id, evaluationData) =>
  api.post(`/patient/${user_id}/evaluation_consultation/`, evaluationData)

export const remove = async (user_id, consultation_id) => {
  /*eslint-disable-line */
  return await api.post(`/patient/${user_id}/remove_evaluation/`, {
    /*eslint-disable-line */
    consultation_id
  })
}

export const getEvaluation = async (user_id, consultation_id) => {
  /*eslint-disable-line */
  return await api.post(`/patient/${user_id}/get_evaluation/`, {
    /*eslint-disable-line */
    consultation_id
  })
}

export const getProfessionalEvaluations = (user_id, page, data) =>
  api.post(`/professionals/${user_id}/get_evaluations/?page=${page || 1}`, data)

export const getProfessionalBoticarioEvaluations = (user_id, page, data) =>
  api.post(`/professionals/${user_id}/get_boticario_evaluations/?page=${page || 1}`, data)

export const getEvaluationPaged = async (slug, page) =>
  await api.get(`/professionals/${slug}/get_evaluations_paged/?page=${page || 1}`)

export const getAllEvaluations = async (
  params = {
    send_by: undefined, // ["patient" (to get user patients evaluations) or "myself" (to get user evaluations)]
    page: 1,
    per_page: 10,
    anonymous: undefined,
    random: undefined,
    min_stars: undefined,
    max_stars: undefined,
    consultation: undefined,
    professional: undefined,
    patient: undefined
  }
) => {
  if (!['patient', 'myself'].includes(send_by)) {
    throw new Error(
      '`send_by` must be "patient" (to get user patients evaluations) or "myself" (to get user evaluations)'
    )
  }
  const api_params = Object.keys(params)
    .reduce((acc, cur) => {
      if (params[cur] === undefined) return acc
      return [...acc, cur + '=' + params[cur]]
    }, [])
    .join('&')

  return await api.get(`v1/evaluations/${send_by}?${api_params}`)
}

export const getPreviewEvaluations = async (
  params = {
    page: 1,
    per_page: 10,
    random: undefined,
    min_stars: undefined,
    max_stars: undefined
  }
) => {
  const api_params = Object.keys(params)
    .reduce((acc, cur) => {
      if (params[cur] === undefined) return acc
      return [...acc, cur + '=' + params[cur]]
    }, [])
    .join('&')

  return await api.get(`v1/evaluations/preview?${api_params}`)
}

export const submitCallEvaluation = data => api.post('/call_quality_evaluation/', data)
