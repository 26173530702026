import React, { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { HeaderRendering } from 'components/v2/Header'
import { DownloadAppCard } from 'components/v2/DownloadAppCard'
import FooterIntercomClickableHome from 'components/shared/FooterStatic/FooterIntercomClickableHome'

import Wrapper from 'components/Wrapper'
import { sendHomeViewEvent } from 'components/shared/DataLayer'

import HowItWorks from 'containers/Home/components/HowItWorks'
import OurClientsCarousel from 'containers/Home/components/OurClientsCarousel'
import KnowMore from 'containers/Home/components/Knowmore'
import Banner from 'containers/Home/components/Banner'
import ScheduleAppointmentHome from 'containers/Home/components/ScheduleAppointmentHome'
import Blog from 'containers/Home/components/Blog'
import FrequentlyQuestions from 'containers/Home/components/FrequentlyQuestions'
import Testimonials from 'containers/Home/components/Testimonials'
import WhyDoTherapy from 'containers/Home/components/WhyDoTherapy'
import FirstFold from 'containers/Home/components/FirstFold'
import { useFeatureFlags } from 'hooks'

const DynamicModal = dynamic(() => import('containers/Home/components/FirstFold/SearchModal'), {
  ssr: false
})

const metaTags = {
  title: 'Terapia Online Onde Você Estiver | Vittude',
  description:
    'Faça Terapia Online e dê o primeiro passo no autocuidado. A Vittude conecta você a psicólogos de diversas linhas terapêuticas em um ambiente totalmente seguro.',
  canonical: `${process.env.VITTUDE_OFFLINE_URL}`
}

const Home = ({ posts }) => {
  const { frontend_is_boticario_benefit_modal_feature_enabled } = useFeatureFlags(
    'frontend_is_boticario_benefit_modal_feature_enabled'
  )

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isBoticarioModalOpen, setIsBoticarioModalOpen] = useState(false)
  const [vittudeMatch, setVittudeMatch] = useState(false)
  const [firstStep, setFirstStep] = useState(1)

  const handleModal = (isVittudeMatch, step = 1) => {
    if (isVittudeMatch) {
      setVittudeMatch(true)
    }

    setFirstStep(step)
    setIsModalOpen(!isModalOpen)
  }

  useEffect(() => {
    sendHomeViewEvent({
      location: window.location.href,
      referrer: document.referrer,
      title: metaTags.title
    })
  }, [])

  return (
    <Wrapper
      noIndex={false}
      cssFile={[]}
      home={true}
      metaTags={metaTags}
      loadGoogleMapsScript={true}
    >
      <HeaderRendering openModal={handleModal} />
      <FirstFold
        handleModal={handleModal}
        frontend_is_boticario_benefit_modal_feature_enabled={
          frontend_is_boticario_benefit_modal_feature_enabled
        }
        isBoticarioModalOpen={isBoticarioModalOpen}
        setIsBoticarioModalOpen={setIsBoticarioModalOpen}
      />
      <OurClientsCarousel />
      <Banner />
      <HowItWorks />
      <ScheduleAppointmentHome />
      <KnowMore />
      <WhyDoTherapy />
      <Testimonials />
      {posts.length > 0 && <Blog posts={posts} />}
      <FrequentlyQuestions />

      <FooterIntercomClickableHome />
      {/* Search Modal */}
      <DynamicModal
        isModalOpen={isModalOpen}
        toggleModal={handleModal}
        isBoticarioModalOpen={isBoticarioModalOpen}
        setIsBoticarioModalOpen={setIsBoticarioModalOpen}
        isVittudeMatch={vittudeMatch}
        firstStep={firstStep}
      />
      <DownloadAppCard />
    </Wrapper>
  )
}

export default Home
